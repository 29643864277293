// english
import MainContentENG from './eng/Main/mainContent.json';
import MainTermsENG from './eng/Main/mainTerms.json';
import Effects from './effects.json';
import TechDataENG from './eng/TechData/TechData.json';
import ContactENG from './eng/Contact/contact.json';
import ManifestENG from './eng/Manifest/manifest.json';

export default function (page, language)
{
    switch (language) {
        case 'eng':
            {
                switch (page) {
                    case 'MainContent':
                        return MainContentENG;
                    case 'MainTerms':
                        return MainTermsENG;
                    case 'TechData':
                        return TechDataENG;
                    case 'Contact':
                        return ContactENG;
                    case 'Manifest':
                        return ManifestENG;
                }
            }
        default:
            {
                if (page === 'Effects') {
                    return Effects;
                }
            }
    }
}